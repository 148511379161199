//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

@import url('https://fonts.googleapis.com/css2?family=Almarai&family=Cairo:wght@300&family=Noto+Sans+Arabic&display=swap');
//@import url('https://fonts.cdnfonts.com/css/circular-std');

$primary: #f05423;
$font-family-sans-serif: "Almarai", sans-serif !default;
$font-family-monospace: "Almarai", sans-serif !default;

html[lang="ar"]  {
  font-family: "Almarai", sans-serif !important;
  $font-family-sans-serif: "Almarai", sans-serif !default;
  $font-family-monospace: "Almarai", sans-serif !default;
}

html[lang="en"] {
  font-family: "Circular Std", sans-serif !important;
  $font-family-sans-serif: "Circular Std", sans-serif !default;
  $font-family-monospace: "Circular Std", sans-serif !default;
}
